import React from "react";
import lysIcon from "../../../assets/images/lysIcon.svg";

const NftCardPrice = ({ title, nftCardImg, showFooter = true, artist }) => {
  return (
    <>
      <div className="min-w-[290px] max-w-[420px]  mx-auto sm:mx-0 w-full">
        <div className="relative">
          <div
            className="w-full h-[400px] bg-center bg-cover"
            style={{ backgroundImage: `url(${nftCardImg})` }}
          ></div>
          <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-70 p-4">
            <h3 className="text-center text-2xl font-bold mb-2 uppercase">
              {title}
            </h3>
            <p className="text-center">
              By: <span className="text-red">{`${artist.aspen_tokens[0]?.artists[0]?.first_name} ${artist.aspen_tokens[0]?.artists[0]?.surname}`}</span>
            </p>
          </div>
        </div>
        {showFooter && (
          <div className="flex justify-between p-4 bg-mutedGray">
            <p>#5 / 30</p>
            <div className="flex gap-x-2">
              <img src={lysIcon} alt="lysIcon" />
              <h4 className="text-lg font-medium">
                1.8 {process.env.ACTIVE_CRYPTO_NAME}
              </h4>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NftCardPrice;
