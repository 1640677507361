import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import BannerCollections from "../../components/molecules/banners/BannerCollections";
import BannerFeaturedDrops from "../../components/molecules/banners/BannerFeaturedDrops";
import { useStaticQuery, graphql } from "gatsby";
import urlConstants from "../../constants/urlConstants.js";
import MarketplacePageBanner from "../../components/molecules/banners/MarketplacePageBanner";
const apiService = require("../../services/apiService.js");

//get(url, form = { getHeaders: () => { return {} } }){

const IndexPage = ({ pageContext }) => {
  const bannerData = {
    title: "Latest | NFT Drops",
    url: "/marketplace/drops",
    button_text: "View All Available Drops",
  };

  /**
  Get active NFT drops
  This needs to be a real time request to the api
*/

  const [dropList, setDropList] = useState([]);
  const [liveDropList, setLiveDropList] = useState([]);
  const [serverTime, setServerTime] = useState();

  useEffect(() => {
    apiService.getter(urlConstants.getLatestDropsUrl).then((response) => {
      const drops = response.data.drop;
      setServerTime(response?.data?.systemNow);
      const currentDate = new Date(response.data.systemNow);
      const expired = drops.filter((drop) => {
        return new Date(drop.drop_end) < currentDate;
      });
      const live = drops.filter((drop) => {
        return (
          new Date(drop.drop_end) > currentDate &&
          new Date(drop.drop_start) < currentDate
        );
      });
      const yetToStart = drops.filter((drop) => {
        return new Date(drop.drop_start) > currentDate;
      });
      const newDropsList = [...live, ...yetToStart, ...expired];
      setDropList(drops);
      setLiveDropList(newDropsList);
    });
  }, []);

  const { strapiBannerCollection } = useStaticQuery(graphql`
    query {
      strapiBannerCollection {
        step_a_header
        step_a_text
        step_b_header
        step_b_text
        step_c_header
        step_c_text
        title
      }
    }
  `);

  return (
    <Layout>
      <MarketplacePageBanner dropList={liveDropList} />

      <BannerFeaturedDrops
        bannerData={bannerData}
        drops={dropList}
        serverTime={serverTime}
      />

      <BannerCollections bannerData={strapiBannerCollection} />
    </Layout>
  );
};

export default IndexPage;
