import React, { useEffect, useState } from "react";
import TwoColorHeader from "../../atoms/TwoColorHeader";
import BannerCard from "../nftCards/BannerCard";
import marketplace_banner from "../../../assets/images/marketplace_banner.png";
import { Link } from "gatsby";

const MarketplacePageBanner = ({ dropList }) => {
  const [bannerToken, setBannerToken] = useState();
  useEffect(() => {
    if (dropList.length) {
      const token = dropList[0]?.aspen_tokens[0];
      setBannerToken({
        name: token?.name,
        img: token?.featured_image?.url,
        artists: token?.artists,
        link: `/marketplace/nfts/${token?.aspen_collection?.artefy_collection?.slug}/${token.slug}`,
      });
    }
  }, [dropList]);
  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${marketplace_banner})`,
        }}
        className="pt-32 pb-10 flex flex-col md:flex-row justify-center items-center gap-y-12 md:gap-y-0 md:gap-x-12 lg:gap-x-24 px-12 bg-cover bg-center"
      >
        <div className="flex flex-col items-center gap-y-4 md:items-start md:gap-y-8">
          <TwoColorHeader
            title="web3|Marketplace"
            colorClassB="text-primary"
            className="text-4xl lg:text-6xl font-extrabold text-center md:text-left normal-case"
          />
          <span className="text-3xl lg:text-4xl text-center md:text-left">
            Discover, collect, and sell Metal+ web3 collectibles
          </span>
          <Link to="/marketplace/nfts/collections">
            <button className="bg-primary py-3 px-8 lg:px-16 mt-4">
              Explore Collections
            </button>
          </Link>
        </div>
        <div>
          <BannerCard token={bannerToken} />
        </div>
      </div>
    </>
  );
};

export default MarketplacePageBanner;
