import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "gatsby";

const BannerCard = ({ token }) => {
  return (
    <>
      <div className=" md:w-[400px] xl:w-[450px] mx-auto md:mx-0">
        <div
          className="w-full h-[250px] sm:h-[350px] bg-center bg-cover"
          style={{ backgroundImage: `url(${token?.img})` }}
        ></div>
        <div className="flex justify-center items-center gap-x-8 sm:gap-x-24 px-1 sm:px-5 py-5 bg-black">
          <div className="flex flex-col">
            <span className="font-bold uppercase">{token?.name}</span>
            <span>
              {token?.artists.map((artist) => {
                return (
                  <span key={artist?.id}>
                    {artist?.first_name} {artist?.surname}
                  </span>
                );
              })}
            </span>
          </div>
          <div className="text-primary text-lg font-bold cursor-pointer">
            <Link to={token?.link}>
              View <ArrowForwardIcon />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerCard;
