import React, { useState } from "react";
import TwoColorHeader from "../../atoms/TwoColorHeader";
import Button from "../../atoms/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import LoginModal from "../../organisms/LoginModal";
import { isUserAuthenticated } from "../../../utils/utils";
import { Link } from "gatsby";

const BannerCollections = ({ bannerData }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const handleLogin = () => {
    setShowLoginModal(true);
  };
  return (
    <div className="bg-bgDarkGray bg-opacity-60">
      <div className="pt-16 pb-16">
        <div className="flex flex-col justify-center items-center gap-y-4">
          <TwoColorHeader
            title={bannerData.title}
            colorClassB="text-primary"
            whiteCount={2}
          />

          <div className="mt-8 flex lg:flex-row flex-col">
            <div className="flex mb-4">
              <div className="grid place-items-center flex-none align-middle pr-4 text-primary text-5xl font-primary font-extrabold">
                01
              </div>

              <div className="border-l border-primary pl-4 pb-4 pr-4 flex-none w-64">
                <h3 className="font-bold text-lg">
                  {bannerData.step_a_header}
                </h3>
                <p className="text-gray text-sm pt-4 lg:w-40">
                  {bannerData.step_a_text}
                </p>
              </div>
            </div>

            <div className="flex mb-4">
              <div className="grid place-items-center flex-none align-middle pr-4 text-primary text-5xl font-primary font-extrabold">
                02
              </div>

              <div className="border-l border-primary pl-4 pb-4 pr-4 flex-none w-64">
                <h3 className="font-bold text-lg">
                  {bannerData.step_b_header}
                </h3>
                <p className="text-gray text-sm pt-4 lg:w-40">
                  {bannerData.step_b_text}
                </p>
              </div>
            </div>

            <div className="flex mb-4">
              <div className="grid place-items-center flex-none align-middle pr-4 text-primary text-5xl font-primary font-extrabold">
                03
              </div>

              <div className="border-l border-primary pl-4 pb-4 flex-none w-64">
                <h3 className="font-bold text-lg">
                  {bannerData.step_c_header}
                </h3>
                <div className="text-gray text-sm pt-4 lg:w-40">
                  {bannerData.step_c_text}
                </div>
              </div>
            </div>
          </div>

          <div className="flex lg:flex-row flex-col justify-center">
            {!isUserAuthenticated() && (
              <div className="p-4 flex-none w-70 text-center">
                <Button
                  className="w-64 border-2 border-primary bg-primary pt-2 pb-2 pl-4 pr-4 text-sm"
                  children="Create Account"
                  arrow={true}
                  onClick={handleLogin}
                />
                <p className="w-64 text-sm mt-4 text-gray">
                  Already a member?{" "}
                  <button className="text-primary" onClick={handleLogin}>
                    Log in here <ArrowRightAltIcon />
                  </button>
                </p>
              </div>
            )}
            <div className="p-4 flex-none w-70 text-center">
              <Link to="/nftfaq">
                <Button
                  type="secondary"
                  className="w-64 border-gray text-gray border-2 pt-2 pb-2 pl-4 pr-4 text-sm"
                  children="What are web3 collectibles??"
                  arrow={true}
                />
              </Link>

              <p className="w-64 text-sm mt-4 text-gray">
                Learn about web3 and how to get started
              </p>
            </div>
          </div>
        </div>
      </div>
      {showLoginModal && (
        <LoginModal
          handleCloseModal={setShowLoginModal.bind(this, false)}
          showLoginModal={showLoginModal}
        />
      )}
    </div>
  );
};

export default BannerCollections;
