import React, { useContext, useState, useEffect } from "react";
import TwoColorHeader from "../../atoms/TwoColorHeader";
// import Button from "../../atoms/Button";
// import NFTCard from "../NFTCard";
import NftCardPrice from "../nftCards/NftCardPrice";
import { Link } from "gatsby";
import { Container } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AppConext from "../../../context/AppContext";

const BannerFeaturedDrops = ({
  bannerData,
  drops = [],
  className = "",
  serverTime,
}) => {
  const [updatedDrops, setUpdatedDrops] = useState(drops);
  const { updateScrollToDropId } = useContext(AppConext);
  useEffect(() => {
    if (drops.length && serverTime) {
      const currentDate = new Date(serverTime);
      const expired = drops.filter((drop) => {
        return new Date(drop.drop_end) < currentDate;
      });
      const live = drops.filter((drop) => {
        return (
          new Date(drop.drop_end) > currentDate &&
          new Date(drop.drop_start) < currentDate &&
          drop.aspen_tokens.length
        );
      });
      live.reverse();
      const yetToStart = drops.filter((drop) => {
        return (
          new Date(drop.drop_start) > currentDate && drop.aspen_tokens.length
        );
      });
      yetToStart.reverse();
      const newDropsList = [...live, ...yetToStart];
      setUpdatedDrops(newDropsList.slice(0, 3));
    }
  }, [drops, serverTime]);

  const handleDropLinkClick = (id) => {
    updateScrollToDropId(id);
  };

  return drops === 0 ? (
    <Container maxWidth="lg" className="mt-16 mb-16 text-center">
      <div className="flex justify-center items-center flex-col gap-y-4">
        <div className="loader" />
        Getting featured drops
      </div>
    </Container>
  ) : (
    <Container maxWidth="lg" className="mt-16 mb-16">
      <div className={`${className}`}>
        <div className="flex flex-col justify-center items-center gap-y-4">
          <TwoColorHeader
            title="Latest|Drops"
            colorClassB="text-primary"
            whiteCount={1}
          />

          <Link to={bannerData.url} className="mt-4">
            <span className="text-primary">
              {bannerData.button_text}
              <ArrowForwardIcon />
            </span>
          </Link>
        </div>

        <div className="pt-12">
          <ul className="flex flex-col md:flex-row  justify-center items-center gap-x-4  gap-y-8 flex-wrap">
            {updatedDrops.map((element) => {
              return (
                <li key={element?.id}>
                  <Link
                    to={`/marketplace/drops`}
                    onClick={handleDropLinkClick.bind(this, element?.id)}
                  >
                    <NftCardPrice
                      title={element?.name}
                      nftCardImg={element?.cover_image?.url}
                      showFooter={false}
                      artist={element}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default BannerFeaturedDrops;
